import { SINGLE_TRAVEL_PAGE_QUERY, TENANT_CMS_CONFIG_QUERY } from '@/lib/gql'
import { isTravelOnline } from '@/lib/helpers/travel'
import graphQLClient from '@/lib/api/graphQLClient'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import TravelPage from '@/components/pages/TravelPage'
import SeoHeader from '@/components/elements/seo/SeoHeader'
import cmsConfigObject from '@/entities/cmsConfig/interface'
import travelObject from '@/entities/travel/interface'
import { getTravelsStaticPaths } from '@/lib/helpers/getStaticPaths'
import { TravelIdContext } from '@/app/context'

interface Props {
  travel: travelObject
  cmsConfig: cmsConfigObject
}

const TravelSinglePage = ({ travel, cmsConfig }: Props) => {
  if (!travel.seoSettings.ogImage.publicId) {
    travel.seoSettings.ogImage = travel.mainImage
  }
  return (
    <>
      <SeoHeader seoSettings={travel.seoSettings} />
      <TravelIdContext.Provider value={travel.id as number}>
        <TravelPage travel={travel} cmsConfig={cmsConfig} />
      </TravelIdContext.Provider>
    </>
  )
}

export async function getStaticProps({ params, locale }: any) {
  const travelResponse = await graphQLClient.request(SINGLE_TRAVEL_PAGE_QUERY, { travelId: params.travelSlug }).catch(() => {})
  const travel = travelResponse?.travel
  // const travel: travelObject = await travelEntity.repository.getObject(params.travelSlug, includes).catch(() => {})
  // -> Move to middleware?
  // const redirectUrl = getTravelRedirectUrl(travel)
  // if (redirectUrl) {
  //   return {
  //     redirect: {
  //       destination: redirectUrl,
  //     },
  //     revalidate: 10,
  //   }
  // }

  if (!travel || !isTravelOnline(travel, true)) {
    return {
      props: {},
      notFound: true,
      revalidate: 10,
    }
  }

  const tenantResponse = await graphQLClient.request(TENANT_CMS_CONFIG_QUERY, { tenantId: process.env.NEXT_PUBLIC_TENANT_ID }).catch(() => {})
  return {
    props: {
      cmsConfig: tenantResponse.tenant.cms.config,
      travel: travel,
      ...(await serverSideTranslations(locale, ['common', 'entities', 'theme'])),
    },
    revalidate: 10,
  }
}

export async function getStaticPaths() {
  return {
    paths: await getTravelsStaticPaths(),
    fallback: 'blocking',
  }
}

export default TravelSinglePage
